import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, TableCell, TableRow } from '@mui/material';

import colors from 'src/theme/colors';
import { EsgDmaImpactCategory } from '../../../types';
import { Add } from '@mui/icons-material';
import { useLanguage } from 'src/common/hooks';
import { useDmaImpacts } from 'src/Esg/Dmav2/hooks';
import AcceptableTextField from 'src/components/AcceptableTextField';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  subSubTopicId: number;
}
const emptyImpact = {
  name: '',
  description: '',
  category: EsgDmaImpactCategory.POSITIVE_REAL,
  human_rights_related: false,
  upstream_related: false,
  own_operations_related: false,
  downstream_related: false,
};

export default function AddImpactRow(props: Props) {
  const { subSubTopicId } = props;
  const { t } = useLanguage();
  const { reportId } = useParams();
  const { _instance: esgReport } = useEsgReports({ id: Number(reportId) });
  const { create } = useDmaImpacts({}, { enabled: false });

  const [visible, setVisible] = React.useState(false);

  if (!esgReport) return null;

  const handleSave = (value: unknown) =>
    create
      .mutateAsync({
        ...emptyImpact,
        name: String(value),
        esg_dma_sub_sub_topic_id: subSubTopicId,
        esg_dma_id: esgReport.esg_dma_id,
      })
      .then(() => setVisible(false));

  if (!visible)
    // if not visible, show the add impact button
    return (
      <TableRow>
        <TableCell colSpan={10} sx={{ pl: '64px' }}>
          <Button
            size="small"
            variant="secondary"
            startIcon={<Add />}
            onClick={() => setVisible(true)}
          >
            {t('esg.dma.tableV2.addImpact')}
          </Button>
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
        }}
      >
        <AcceptableTextField
          size="small"
          value=""
          onAccept={handleSave}
          placeholder={t('esg.dma.tableV2.columns.type')}
          onReject={() => setVisible(false)}
          buttonsAlwaysVisible
        />
      </TableCell>
      <TableCell colSpan={7} />
    </TableRow>
  );
}
