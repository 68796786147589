import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, TableCell, TableRow } from '@mui/material';

import { Add } from '@mui/icons-material';
import { useLanguage } from 'src/common/hooks';
import AcceptableTextField from 'src/components/AcceptableTextField';
import themeColors from 'src/theme/colors';
import { useRiskOpportunities } from 'src/Esg/Dmav2/hooks';
import { EsgDmaRiskOpportunityCategory } from 'src/Esg/Dmav2/types';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  subSubTopicId: number;
}
const emptyRiskOpportunity = {
  name: '',
  description: '',
  category: EsgDmaRiskOpportunityCategory.NONE,
  upstream_related: false,
  own_operations_related: false,
  downstream_related: false,
  impact_id: undefined,
};

export default function AddRiskOpportunitiesRow(props: Props) {
  const { subSubTopicId } = props;
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });

  const { create } = useRiskOpportunities({}, { enabled: false });
  const [visible, setVisible] = React.useState(false);

  const { t } = useLanguage({ keyPrefix: 'esg.dma' });

  if (!report) return null;

  const handleSave = (value: unknown) =>
    create
      .mutateAsync({
        ...emptyRiskOpportunity,
        name: String(value),
        esg_dma_id: report?.esg_dma_id,
        esg_dma_sub_sub_topic_id: subSubTopicId,
      })
      .then(() => setVisible(false));

  if (!visible)
    // if not visible, show the add impact button
    return (
      <TableRow>
        <TableCell colSpan={10} sx={{ pl: '64px' }}>
          <Button
            size="small"
            variant="secondary"
            startIcon={<Add />}
            onClick={() => setVisible(true)}
          >
            {t('financialTopicsEvaluationTableV2.columns.addRiskOrOpportunity')}
          </Button>
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow sx={{ height: '70px' }}>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: themeColors.backgroundInput,
        }}
      >
        <AcceptableTextField
          size="small"
          value=""
          onAccept={handleSave}
          placeholder={t(
            'financialTopicsEvaluationTableV2.columns.riskOrOpportunityName'
          )}
          onReject={() => setVisible(false)}
          buttonsAlwaysVisible
        />
      </TableCell>
      <TableCell colSpan={7} />
    </TableRow>
  );
}
